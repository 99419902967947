<template>
    <div class="container">
        <div class="row">
            <chash :teams="teams"></chash>
        </div>
    </div>
</template>

<script>
import Chash from './Units/DesingnerGraps/Chash.vue'
export default {
    components: { Chash },
    props: {
        game: {
            required: true,
        },
    },
    computed: {
        teams() {
            if (this.game) {
                return this.game.teams
            } else {
                return []
            }
        },
    },
}
</script>
