<template>
    <div v-if="ready">
        <data-export-button
            class="float-right"
            :data="csvData"
            :name="'cash.csv'"
        >
        </data-export-button>
        <line-chart
            :width="1050"
            :height="500"
            :chartData="chartData"
            :options="chartOptions"
        />

        <b-form-checkbox v-model="steppedVal" @change="initChatData"
            >Stepped line</b-form-checkbox
        >
    </div>
</template>
<script>
import DataExportButton from '../Plots/DataExportButton.vue'
import LineChart from '../Plots/LineChart.vue'
export default {
    components: { LineChart, DataExportButton },
    props: {
        teams: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            steppedVal: false,
            chartData: null,
            chartOptions: {
                title: {
                    display: true,
                    text: 'Cash value of teams over time',
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Cash',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Days',
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                },
            },
        }
    },
    computed: {
        ready() {
            return !!this.chartData
        },
        csvData() {
            const teams = this.teams
            const cash = teams.map((team) =>
                team.plant ? team.plant.stats.map((s) => s.plant.cash) : []
            )

            if (cash.length === 0) {
                return []
            }

            // find the index of longest array
            let maxLengthIndex = 0
            for (let i = 1; i < cash.length; i++) {
                if (cash[i].length > cash[maxLengthIndex].length) {
                    maxLengthIndex = i
                }
            }

            return [...Array(cash[maxLengthIndex].length).keys()].map((day) => {
                const rtn = {}
                teams.forEach((team, index) => {
                    rtn[team.name] =
                        cash[index].length > day ? cash[index][day] : 'N/A'
                })
                rtn['day'] = day
                return rtn
            })
        },
    },
    methods: {
        initChatData() {
            if (!this.teams || this.teams.length == 0) {
                return
            }

            // try to find a team that has a plant and stats
            let foundTeamIndex = null
            for (let i = 0; i < this.teams.length; i++) {
                if (this.teams[i].plant && this.teams[i].plant.stats) {
                    foundTeamIndex = i
                    break
                }
            }

            if (foundTeamIndex === null) {
                return
            }

            const teams = this.teams
            const stepped = this.steppedVal ? 'middle' : false
            let i = 0
            this.chartData = {
                labels: [
                    ...Array(
                        this.teams[foundTeamIndex].plant.stats.length
                    ).keys(),
                ].map((x) => x + 1),
                datasets: this.teams.map((team) => {
                    const color = (360 / teams.length) * i
                    i += 1
                    return {
                        label: team.name,
                        borderColor: 'hsl(' + color + ', 90%, 60%)',
                        borderWidth: 1.5,
                        lineTension: 0.25,
                        steppedLine: stepped,
                        fill: false,
                        pointRadius: 0,
                        data: team.plant
                            ? team.plant.stats.map((s) => s.plant.cash)
                            : [],
                    }
                }),
            }
        },
    },
    mounted() {
        this.initChatData()
    },
    watch: {
        teams() {
            if (!!this.teams) {
                this.initChatData()
            }
        },
    },
}
</script>
