<template>
    <div class="container p-4">
        <div class="row-md-8">
            <div class="d-flex justify-content-between w-100">
                <div>
                    <span v-b-tooltip.hover :title="cantStartGameReason">
                        <b-button
                            :disabled="!canStartGame"
                            size="sm"
                            class="mr-2"
                            variant="success"
                            v-b-modal.start-game-modal
                        >
                            Start
                        </b-button>
                    </span>
                    <b-button
                        :disabled="!canStopGame"
                        size="sm"
                        variant="danger"
                        @click="abortGame"
                    >
                        Abort
                    </b-button>
                    <span v-b-tooltip.hover :title="asyncDisabledReason">
                        <b-button
                            variant="primary"
                            size="sm"
                            class="ml-2"
                            :disabled="asyncDisabled"
                            @click="asyncGame"
                        >
                            Async
                        </b-button>
                    </span>
                </div>
                <div>
                    <b-button
                        :disabled="!canStopGame"
                        class="mr-2"
                        size="sm"
                        variant="warning"
                        @click="toggleSuspendGame"
                    >
                        {{ isGameSuspended ? 'Resume' : 'Pause' }}
                    </b-button>
                    <b-button
                        :disabled="!canStopGame"
                        class="mr-2"
                        size="sm"
                        variant="primary"
                        @click="toggleFastForwardGame"
                    >
                        Fast-Forward
                    </b-button>
                    <b-button
                        v-b-tooltip.hover
                        :title="'Reset the game a set number of days'"
                        :disabled="!canStopGame"
                        class="mr-2"
                        size="sm"
                        variant="outline-danger"
                        v-b-modal.reset-game-clock-modal
                    >
                        Reset</b-button
                    >
                </div>
                <div>
                    <span v-b-tooltip.hover :title="importTitle">
                        <b-button
                            :disabled="!isGameSaved || !editGameAllowed"
                            size="sm"
                            class="mr-2"
                            @click="importGame"
                        >
                            <b-icon
                                aria-hidden="true"
                                icon="cloud-upload"
                            ></b-icon>
                        </b-button>
                    </span>
                    <span v-b-tooltip.hover :title="exportTitle">
                        <b-button
                            :disabled="!isGameSaved"
                            size="sm"
                            class="mr-2"
                            @click="exportGame"
                        >
                            <b-icon aria-hidden="true" icon="cloud-download">
                            </b-icon>
                        </b-button>
                    </span>

                    <b-button
                        :disabled="!isGameSaved"
                        size="sm"
                        class="mr-2"
                        variant="outline-danger"
                        @click="deleteGame"
                    >
                        Delete
                    </b-button>
                    <span v-b-tooltip.hover :title="notSavedReason">
                        <b-button
                            :disabled="!isGameSaved"
                            size="sm"
                            class="mr-2"
                            variant="outline-primary"
                            v-b-modal.save-template-modal
                        >
                            Save as Template
                        </b-button>
                    </span>
                    <b-button
                        :disabled="!editGameAllowed"
                        size="sm"
                        class="mr-2"
                        variant="primary"
                        @click="saveGame"
                    >
                        Save
                    </b-button>
                    <b-button
                        size="sm"
                        variant="primary"
                        @click="loadGame"
                        :disabled="loading"
                    >
                        <b-icon icon="arrow-clockwise" font-scale="1"></b-icon>
                        Update
                    </b-button>
                </div>
            </div>
        </div>

        <b-tabs content-class="mt-3 min-vh-100" class="mt-3">
            <b-tab title="Main" active>
                <div v-if="loading" class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    Please wait. The game is loading...
                </div>
                <div v-else>
                    <div class="container">
                        <div class="row">
                            <div class="col col-md-6">
                                <label for="game-title" class="text-muted">
                                    Title
                                </label>
                                <b-form-input
                                    id="game-title"
                                    class="font-weight-light"
                                    v-model="game.title"
                                    :readonly="!editGameAllowed"
                                    placeholder="Please enter the game title..."
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col col-md-8">
                                <label
                                    for="game-description"
                                    class="text-muted"
                                >
                                    Description
                                </label>
                                <b-form-textarea
                                    id="game-description"
                                    class="font-italic font-weight-light"
                                    v-model="game.description"
                                    :readonly="!editGameAllowed"
                                    placeholder="Please enter the game description here..."
                                    rows="3"
                                >
                                </b-form-textarea>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col text-muted">
                                <div class="row mt-3">
                                    <div class="col">
                                        <span>Number of prior days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .prior_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            :readonly="!editGameAllowed"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>Number of play days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .play_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            :readonly="!editGameAllowed"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>Number of post days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .post_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            :readonly="!editGameAllowed"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>
                                            Ingame days per real time hour
                                        </span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .days_per_hour
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            step="0.5"
                                            :readonly="!editGameAllowed"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label for="game-cash" class="text-muted">
                                    Initial capital in dollars
                                </label>
                                <b-form-input
                                    id="game-cash"
                                    class="font-weight-light"
                                    v-model="
                                        game.simulation.template.plant.cash
                                    "
                                    :number="true"
                                    type="number"
                                    min="0"
                                    :readonly="!editGameAllowed"
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label for="game-cash" class="text-muted">
                                    Random seed
                                </label>
                                <b-form-input
                                    id="game-cash"
                                    class="font-weight-light"
                                    v-model="game.simulation.template.seed"
                                    :number="true"
                                    type="number"
                                    :readonly="!editGameAllowed"
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <b-form-checkbox
                                    id="game-leaderboard"
                                    v-model="game.leaderboard"
                                    :readonly="!editGameAllowed"
                                >
                                    Show leaderboard to all players
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="row mt-4" v-if="isGameSaved">
                            <div class="col">
                                <label for="gameLink" class="text-muted">
                                    Use the link below to join the game from
                                    within an LTI consumer
                                </label>
                                <b-form-input
                                    id="gameLink"
                                    class="font-weight-light"
                                    :value="gameLink"
                                    readonly
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>
                        <div class="row mt-4" v-if="isGameAsync">
                            <div class="col">
                                <label for="gameLink" class="text-muted">
                                    Use the link below to join the game as an
                                    individual player
                                </label>
                                <b-form-input
                                    id="gameLink"
                                    class="font-weight-light"
                                    :value="gameAsyncLink"
                                    readonly
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab title="Layout" lazy>
                <factory
                    :game.sync="game"
                    :editable="true"
                    @game-reload="loadGame"
                >
                </factory>
            </b-tab>

            <b-tab title="Teams" lazy>
                <teams :thisgame="game" :teams="teams" :loading="loading">
                </teams>
            </b-tab>

            <b-tab title="Brackets" lazy :disabled="bracketDisabled">
                <brackets :game="game"></brackets>
            </b-tab>

            <b-tab title="Graphs" lazy>
                <graphs :game="game"></graphs>
            </b-tab>
        </b-tabs>

        <b-modal
            ref="saveTemplateModal"
            id="save-template-modal"
            title="Save Template"
            @ok="saveGameTemplate"
            centered
        >
            <b-form-checkbox
                id="template-public-checkbox"
                v-model="isTemplatePublic"
                name="template-public-checkbox"
                value="true"
                unchecked-value="false"
            >
                Make this template public
            </b-form-checkbox>
        </b-modal>

        <b-modal
            ref="resetGameClockModal"
            id="reset-game-clock-modal"
            title="Reset Game Clock"
            @ok="resetGameClock"
            centered
        >
            <label for="reset-day" class="text-muted">Clock day</label>
            <b-form-spinbutton
                id="reset-day"
                class="float-right"
                v-model="resetDay"
                size="sm"
                min="1"
                inline
            >
            </b-form-spinbutton>
        </b-modal>

        <ValidationObserver v-slot="{ handleSubmit }">
            <b-modal
                ref="startGameModal"
                id="start-game-modal"
                title="Start Game"
                @ok.prevent="handleSubmit(startGame)"
                centered
            >
                <b-form novalidate>
                    <ValidationProvider
                        name="Date"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group
                            label=""
                            label-for="start-date-input"
                            description=""
                        >
                            <b-form-input
                                v-model="start.date"
                                id="start-date-input"
                                type="date"
                                required
                            ></b-form-input>
                            <span class="small text-danger">{{
                                errors[0]
                            }}</span>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                        name="Time"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group
                            label=""
                            label-for="start-time-input"
                            description=""
                        >
                            <b-form-input
                                v-model="start.time"
                                id="start-time-input"
                                type="time"
                                required
                            ></b-form-input>
                            <span class="small text-danger">
                                {{ errors[0] }}
                            </span>
                        </b-form-group>
                    </ValidationProvider>
                </b-form>
            </b-modal>
        </ValidationObserver>
    </div>
</template>
<script>
import moment from 'moment'
import { gameTemplate } from '@/components/Designer/Game/template'
import { formatDateString, GameStatus } from '@/views'
import ApiService from '@/services/api.service'
import { toFormattedCurrency } from '@/services/formatting'
import Brackets from '../components/Designer/Brackets.vue'
import Graphs from '../components/Designer/Graphs.vue'
import Teams from '../components/Designer/Teams.vue'

function padZeros(str) {
    if (typeof str !== 'string') {
        str = '' + str
    }
    if (str.length < 2) {
        return '0' + str
    } else {
        return str
    }
}

const date = new Date()
const hours = padZeros(date.getHours())
const minutes = padZeros(date.getMinutes())

export default {
    components: { Brackets, Graphs, Teams },
    data() {
        return {
            loading: true,
            game: null,
            isTemplatePublic: false,
            resetDay: 1,
            start: {
                date: date.toISOString().substr(0, 10),
                time: hours + ':' + minutes + ':00',
            },
            teams: {
                newTeamName: '',
                bracket: 'bracket',
                table: {
                    data: [],
                    fields: [
                        'name',
                        'created',
                        'bracket',
                        'users',
                        'cash',
                        'actions',
                    ],
                },
            },
        }
    },
    computed: {
        gameHasTeams: function () {
            return this.teams.table.data.length
        },
        asyncDisabled: function () {
            if (this.isGameSaved) {
                if (!!this.game) {
                    // game obj. valid
                    if (this.game.status !== GameStatus.INITIALIZED) {
                        return true
                    } else {
                        return this.game.status == GameStatus.ASYNC
                    }
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        asyncDisabledReason() {
            if (this.asyncDisabled) {
                if (!this.isGameSaved) {
                    return this.notSavedReason
                } else if (this.game.status == GameStatus.ASYNC) {
                    return 'The game is already asnc.'
                } else if (this.game.status !== GameStatus.INITIALIZED) {
                    return 'The game was already started'
                } else {
                    return 'Unknown reason'
                }
            } else {
                return ''
            }
        },
        isGameSuspended: function () {
            return !!this.game && this.game.status == GameStatus.SUSPENDED
        },
        isGameStarted: function () {
            return !!this.game && this.game.status != GameStatus.INITIALIZED
        },
        canStopGame: function () {
            return (
                !!this.game &&
                [GameStatus.SUSPENDED, GameStatus.STARTED].includes(
                    this.game.status
                )
            )
        },
        canStartGame: function () {
            return (
                !!this.game &&
                this.game.status == GameStatus.INITIALIZED &&
                this.gameHasTeams
            )
        },
        cantStartGameReason() {
            if (!this.isGameSaved) {
                return 'The game has to be saved.'
            } else if (this.game.status === GameStatus.ASYNC) {
                return 'The game is asynchronous.'
            } else if (this.game.status != GameStatus.INITIALIZED) {
                return 'The game was already started.'
            } else if ((this.game.teams ?? []).length == 0) {
                return 'The game has no teams.'
            } else if ((this.game.teams ?? []).length != 0) {
                return 'Start the game.'
            }
        },
        importTitle() {
            if (this.isGameSaved) {
                return 'Import'
            } else {
                return 'The game has to be saved.'
            }
        },
        exportTitle() {
            if (this.isGameSaved) {
                return 'Export'
            } else {
                return 'The game has to be saved.'
            }
        },
        notSavedReason() {
            if (this.isGameSaved) {
                return ''
            } else {
                return 'The game has to be saved.'
            }
        },
        editGameAllowed: function () {
            return !!this.game && this.game.status == GameStatus.INITIALIZED
        },
        isGameSaved: function () {
            return Boolean((this.game ?? {}).id)
        },
        isGameAsync: function () {
            return this.game.status == GameStatus.ASYNC
        },
        gameLink: function () {
            if (!!this.game && this.game.status === GameStatus.ASYNC) {
                return ApiService.gameLinkAsync(this.game.id)
            } else {
                return !!this.game ? ApiService.gameLink(this.game.id) : ''
            }
        },
        gameAsyncLink: function () {
            return !!this.game ? ApiService.gameLinkAsyncIndv(this.game.id) : ''
        },
        gameMode: function () {
            return (this.game ?? {}).mode
        },
        bracketDisabled: function () {
            return this.game && this.game.status === GameStatus.ASYNC
        },
        bracketDisabledReason: function () {
            return this.bracketDisabled
                ? 'Async games use the teams name as its brakcet.'
                : ''
        },
    },
    methods: {
        asyncGame() {
            ApiService.asyncGame(this.game.id).then(
                (response) => {
                    this.$bvToast.toast(response.data.message, {
                        title: 'Async Game',
                        toaster: 'b-toaster-top-center',
                        variant: 'success',
                        solid: true,
                    })

                    this.loadGame()
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        startGame() {
            this.$refs.startGameModal.hide()
            const startDate = moment(`${this.start.date}T${this.start.time}`)

            ApiService.startGame(this.game.id, startDate).then(
                (response) => {
                    this.$bvToast.toast(response.data.message, {
                        title: 'Start Game',
                        toaster: 'b-toaster-top-center',
                        variant: 'success',
                        solid: true,
                    })

                    this.loadGame()
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        abortGame() {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Abort Game',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }

                    ApiService.abortGame(this.game.id).then(
                        (response) => {
                            this.loadGame()
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
        toggleSuspendGame() {
            this.$bvModal
                .msgBoxConfirm(
                    this.game.status === 'STARTED'
                        ? 'Pause the game?'
                        : 'Resume the game?',
                    {
                        title: 'Suspend',
                        centered: true,
                    }
                )
                .then((success) => {
                    if (success) {
                        ApiService.toggleSuspendGame(this.game.id).then(
                            (response) => {},
                            (error) => {
                                this.$bvToast.toast(
                                    error.response.data.message,
                                    {
                                        title: 'Error',
                                        toaster: 'b-toaster-top-center',
                                        variant: 'danger',
                                        solid: true,
                                    }
                                )
                            }
                        )
                    }
                    this.loadGame()
                    this.loadGame()
                })
        },
        toggleFastForwardGame() {
            ApiService.toggleFastForwardGame(this.game.id).then(
                (response) => {
                    this.$bvToast.toast(response.data.message, {
                        title: 'Game State',
                        toaster: 'b-toaster-top-center',
                        variant: 'warning',
                        solid: true,
                    })

                    this.game.mode = response.data.mode
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        resetGameClock() {
            this.$refs.resetGameClockModal.hide()

            ApiService.resetGameClock(this.game.id, this.resetDay).then(
                (response) => {
                    this.$bvModal
                        .msgBoxConfirm(response.data.message, {
                            title: 'Reset',
                            centered: true,
                        })
                        .then((success) => {
                            this.loadGame()
                        })
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        getGame() {
            return {
                title: this.game.title,
                description: this.game.description,
                simulation: this.game.simulation,
                layout: this.game.layout,
                _bracketing_behavior: this.game.bracketing_behavior,
                number_of_brackets: this.game.number_of_brackets,
                leaderboard: this.game.leaderboard,
            }
        },
        async importGame() {
            function showOpenFilePickerPolyfill(options) {
                return new Promise((resolve) => {
                    const input = document.createElement('input')
                    input.type = 'file'
                    input.multiple = options.multiple
                    input.accept = options.types
                        .map((type) => type.accept)
                        .flatMap((inst) =>
                            Object.keys(inst).flatMap((key) => inst[key])
                        )
                        .join(',')

                    input.addEventListener('change', () => {
                        resolve(
                            [...input.files].map((file) => {
                                return {
                                    getFile: async () =>
                                        new Promise((resolve) => {
                                            resolve(file)
                                        }),
                                }
                            })
                        )
                    })

                    input.click()
                })
            }

            if (typeof window.showOpenFilePicker !== 'function') {
                window.showOpenFilePicker = showOpenFilePickerPolyfill
            }

            const files = await showOpenFilePicker({
                multiple: false,
                types: [
                    {
                        description: 'INSYSTED',
                        accept: { 'ino/*': ['.ins', '.INS', '.json'] },
                    },
                ],
            })
            const file = await files[0].getFile()
            const text = await file.text()

            let obj
            try {
                obj = JSON.parse(text)
            } catch (ex) {
                this.$bvToast.toast(
                    'The file contains invalid json: ' + ex.message + '.',
                    {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    }
                )
                return
            }

            // check, that all attributes are present
            if (
                ['title', 'description', 'simulation', 'layout']
                    .map((atrib) => typeof obj[atrib] === 'undefined')
                    .some((x) => x)
            ) {
                this.$bvToast.toast('The file is malformed.', {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    variant: 'danger',
                    solid: true,
                })
                return
            }

            // save and upload
            this.game.title = obj.title
            this.game.description = obj.description
            this.game.simulation = obj.simulation
            this.game.layout = obj.layout

            this.saveGame()
        },
        exportGame() {
            const game = {}
            Object.assign(game, this.getGame()) // copy the game
            delete game.simulation.clock // remove the clock as it containes data regarding the state of this specific game

            const json = JSON.stringify(game, null, 2)
            const blob = new Blob([json], { type: 'application/json' })
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = window.URL.createObjectURL(blob)
            a.download = this.game.title + '.json'
            document.body.appendChild(a)
            a.click()
            a.remove()
        },
        saveGame() {
            const payload = this.getGame()

            ApiService.saveGame(payload, this.game.id).then(
                (response) => {
                    if (!this.game.id) {
                        this.$router.push({
                            name: 'designGame',
                            params: {
                                gameId: response.data.game_id,
                            },
                        })

                        location.reload()
                    } else {
                        this.$bvToast.toast(response.data.message, {
                            title: 'Information',
                            toaster: 'b-toaster-top-center',
                            variant: 'success',
                            solid: true,
                        })
                    }
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        saveGameTemplate() {
            this.$refs.saveTemplateModal.hide()
            ApiService.saveTemplate(this.game.id, this.isTemplatePublic).then(
                (response) => {
                    this.$bvToast.toast(response.data.message, {
                        title: 'Information',
                        toaster: 'b-toaster-top-center',
                        variant: 'success',
                        solid: true,
                    })
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        deleteGame() {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Delete Game',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }

                    ApiService.deleteGame(this.game.id).then(
                        (response) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
        loadGame() {
            this.loading = true
            this.game = null

            if (!this.$route.params.gameId) {
                this.game = gameTemplate
                this.loading = false

                return
            }

            ApiService.getGame({ id: this.$route.params.gameId }).then(
                (response) => {
                    this.game = response.data.data
                    this.teams.table.data = []
                    this.game.teams.forEach((team) => {
                        this.teams.table.data.push({
                            id: team.id,
                            name: team.name,
                            created: formatDateString(team.created_at),
                            bracket: team.bracket,
                            cash: toFormattedCurrency(team.cash),
                            users: team.users.length,
                        })
                    })

                    this.loading = false
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        })
                }
            )
        },
    },
    mounted() {
        this.loadGame()
    },
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.css';
</style>
