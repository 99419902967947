<template>
    <div class="container">
        <div class="row">
            <p class="text-muted">
                Each team that is created via Lti is assigned to a bracket. To
                which bracket is decided by the Brackering behavior.
            </p>
            <div class="col col-md-6">
                <label for="brackering-behavior" class="text-muted"
                    >Brackering behavior</label
                >
                <b-form-select
                    name="brackering-behavior"
                    :options="behaviorOptions"
                    v-model="game.bracketing_behavior"
                >
                </b-form-select>
            </div>
            <div class="col" v-if="!disableNumberOfBrackets">
                <label for="number-of-brackets">Number of brackets</label>
                <b-form-input
                    name="number-of-brackets"
                    type="number"
                    v-model="game.number_of_brackets"
                    :disabled="disableNumberOfBrackets"
                >
                </b-form-input>
            </div>
        </div>
        <div class="row mt-3 col">
            <p class="text-muted">{{ text }}</p>
        </div>
    </div>
</template>
<script>
import {
    BracketingBehaviors,
    BracketingBehaviorDisplayMap,
    BracketingBehaviorApiMap,
    BracketingBehavior,
} from '../../views/index.js'

export default {
    props: {
        game: {
            type: Object,
            required: true,
        },
    },
    computed: {
        text() {
            return [
                'Each teams name is used to assing the team to a bracket.',
                'Teams are assingned to the bracket with the fewest teams.',
                'The moodle api is used to assing the teams to brakets based on groupings set up in moodle.',
                "The lti parameter 'bracket' is used.",
            ][BracketingBehaviorApiMap[this.game.bracketing_behavior]]
        },
        behaviorOptions() {
            return BracketingBehaviors.map((b) => {
                return {
                    value: BracketingBehaviorApiMap[b],
                    text: BracketingBehaviorDisplayMap[b],
                }
            })
        },
        disableNumberOfBrackets() {
            const enabled = [
                BracketingBehavior.HashBased,
                BracketingBehavior.RoundRobin,
            ]
            return !enabled.includes(
                BracketingBehaviorApiMap[this.game.bracketing_behavior]
            )
        },
    },
}
</script>
