<template>
    <div class="container">
        <div class="d-flex justify-content-between w-100">
            <div>
                <span v-b-tooltip.hover :title="notSavedReason">
                    <b-button
                        class="ml-auto"
                        :disabled="!isGameSaved"
                        size="sm"
                        variant="primary"
                        v-b-modal.new-team-modal
                    >
                        New Team
                    </b-button>
                </span>
            </div>
            <div>
                <json-excel
                    class="btn btn-outline-primary"
                    :data="teamsWithLink"
                    :fields="{ name: 'name', link: 'link' }"
                    :name="'teams' + game.title + '.csv'"
                    :escapeCsv="false"
                    type="csv"
                >
                    <b-icon icon="download" font-scale="0.85"></b-icon>
                    Download links
                </json-excel>
            </div>
            <div>
                <b-form-checkbox @input="showMembers($event)">
                    Show team members
                </b-form-checkbox>
            </div>
        </div>

        <div class="row">
            <b-table
                class="mt-3 w-100 min-vh-100 table table-sm text-xsmall font-weight-light"
                :items="teams.table.data"
                :fields="teams.table.fields"
                :busy="cloading"
                hover
                small
                select-mode="single"
                sticky-header
                head-variant="light"
                responsive="sm"
                ref="gameTable"
                selectable
                show-empty
                fixed
                @row-selected="onTeamRowSelect"
            >
                <template v-slot:cell(actions)="row">
                    <span>
                        <b-button
                            :disabled="!joinTeamAllowed"
                            size="sm"
                            variant="primary"
                            @click="copyUrl(row.index)"
                        >
                            copy join link
                        </b-button>
                    </span>
                    <span>
                        <b-button
                            :disabled="!deleteTeamAllowed"
                            size="sm"
                            variant="outline-danger"
                            @click="onDeleteTeamClick(row.index)"
                        >
                            <b-icon icon="Trash"></b-icon>
                        </b-button>
                    </span>
                </template>

                <template #empty>
                    <div class="text-center text-muted my-2">
                        There are no records to show
                    </div>
                </template>

                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        Loading...
                    </div>
                </template>
            </b-table>
        </div>
        <b-modal
            ref="newTeamModal"
            id="new-team-modal"
            title="Save New Team"
            @ok="saveNewTeam"
            centered
        >
            <b-form-group label="Team name:" label-for="team_name">
                <b-form-input
                    id="team_name"
                    required
                    v-model="teams.newTeamName"
                    placeholder="Enter a team name..."
                    v-b-popover.hover.top="
                        'Multiple teams may be separated by a comma, use (prefix)n1:n2 to create the teams n1 to n2, e.g. A2:4 creates team A2,A3, and A4.'
                    "
                >
                </b-form-input>
            </b-form-group>
            <b-form-group
                label="Bracket:"
                label-for="bracket"
                v-b-tooltip.hover.top
                :title="bracketDisabledReason"
            >
                <b-form-input
                    :disabled="bracketDisabled"
                    required
                    id="bracket"
                    v-model="teams.bracket"
                    placeholder="Enter a bracket..."
                >
                </b-form-input>
            </b-form-group>
        </b-modal>
    </div>
</template>
<script>
import { gameTemplate } from '@/components/Designer/Game/template'
import { formatDateString, GameStatus } from '@/views'
import ApiService from '@/services/api.service'
import { toFormattedCurrency } from '@/services/formatting'
import JsonExcel from 'vue-json-excel'

export default {
    components: {
        JsonExcel,
    },
    data() {
        return {
            cloading: this.loading,
            game: this.thisgame,
        }
    },
    props: {
        thisgame: {
            required: true,
        },
        teams: {
            required: true,
        },
        loading: {
            required: true,
        },
    },
    computed: {
        notSavedReason() {
            if (this.isGameSaved) {
                return ''
            } else {
                return 'The game has to be saved.'
            }
        },
        isGameSaved: function () {
            return Boolean((this.game ?? {}).id)
        },
        deleteTeamAllowed: function () {
            return !!this.game
        },
        joinTeamAllowed: function () {
            return (
                !!this.game &&
                (this.game.status === GameStatus.INITIALIZED ||
                    this.game.status === GameStatus.ASYNC)
            )
        },
        teamPlayingAllowed: function () {
            return !!this.game && this.game.status !== GameStatus.INITIALIZED
        },
        bracketDisabled: function () {
            return this.game && this.game.status === GameStatus.ASYNC
        },
        bracketDisabledReason: function () {
            return this.bracketDisabled
                ? 'Async games use the teams name as its brakcet.'
                : ''
        },
        teamsWithLink: function () {
            return this.teams.table.data.map((team) => {
                return {
                    ...team,
                    link: `${window.location.origin}/game/join/${team.id}`,
                }
            })
        },
    },
    methods: {
        showMembers: function (event) {
            if (event) {
                this.cloading = true
                this.game = null

                if (!this.$route.params.gameId) {
                    this.game = gameTemplate
                    this.cloading = false
                    return
                }

                ApiService.getGame({ id: this.$route.params.gameId }).then(
                    (response) => {
                        this.game = response.data.data
                        this.teams.table.data = []
                        this.game.teams.forEach((team) => {
                            let s = ''
                            for (let i = 0; i < team.users.length; i++) {
                                s = s + team.users[i].name + ' / '
                            }
                            s = s.substring(0, s.length - 2)
                            this.teams.table.data.push({
                                id: team.id,
                                name: team.name,
                                created: formatDateString(team.created_at),
                                bracket: team.bracket,
                                cash: toFormattedCurrency(team.cash),
                                users: s,
                            })
                        })
                        this.cloading = false
                    },
                    (error) => {
                        this.$bvModal
                            .msgBoxOk(error.response.data.message, {
                                title: 'Error',
                                centered: true,
                            })
                            .then((success) => {
                                this.$router
                                    .push({ name: 'design' })
                                    .catch((err) => {})
                            })
                    }
                )
            } else {
                this.loadGame()
            }
        },
        loadGame() {
            this.cloading = true
            this.game = null

            if (!this.$route.params.gameId) {
                this.game = gameTemplate
                this.cloading = false

                return
            }

            ApiService.getGame({ id: this.$route.params.gameId }).then(
                (response) => {
                    this.game = response.data.data
                    this.teams.table.data = []
                    this.game.teams.forEach((team) => {
                        this.teams.table.data.push({
                            id: team.id,
                            name: team.name,
                            created: formatDateString(team.created_at),
                            bracket: team.bracket,
                            cash: toFormattedCurrency(team.cash),
                            users: team.users.length,
                        })
                    })

                    this.cloading = false
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        })
                }
            )
        },
        saveNewTeam() {
            this.$refs.newTeamModal.hide()

            const newTeamName = this.teams.newTeamName
                ? this.teams.newTeamName
                : 'Default Team'

            const createTeam = ((name, bracket, callback) => {
                ApiService.saveTeam(this.game.id, name, bracket).then(
                    callback,
                    (error) => {
                        callback(true)
                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            variant: 'danger',
                            solid: true,
                        })
                    }
                )
            }).bind(this)

            let names = []
            if (/^(.*?)\d+:\d+$/.test(newTeamName)) {
                const match = /(.*?)(\d+):(\d+)/.exec(newTeamName)
                const n1 = parseInt(match[2])
                const n2 = parseInt(match[3])
                // add names n1..n2 to the list
                for (let i = n1; i <= n2; i++) {
                    names.push(match[1] + i.toString())
                }
            } else {
                names = newTeamName.split(',')
            }
            let numCompleted = 0
            const cb = (() => {
                numCompleted++
                if (numCompleted === names.length) {
                    this.loadGame()
                }
            }).bind(this)

            const delayPerReq = 50
            for (let i = 0; i < names.length; i++) {
                setTimeout(() => {
                    createTeam(
                        names[i],
                        this.bracketDisabled ? names[i] : this.teams.bracket,
                        cb
                    )
                }, delayPerReq * i)
            }
        },
        onTeamRowSelect(items) {
            if (this.teamPlayingAllowed) {
                this.$router
                    .push({
                        name: 'gamePlay',
                        params: {
                            teamId: items[0].id,
                        },
                    })
                    .catch((err) => {})
            } else return
        },
        copyTextSafe(text) {
            try {
                navigator.clipboard.writeText(text)
            } catch (err) {
                console.error('Failed to copy: ', err)
            }
        },
        copyUrl(index) {
            this.copyTextSafe(this.teamsWithLink[index].link)
        },
        onDeleteTeamClick(index) {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Delete Team',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }
                    const delid = this.teams.table.data[index].id
                    ApiService.deleteTeam(delid).then(
                        (response) => {
                            this.$router
                                .push({
                                    name: 'designGame',
                                    params: {
                                        gameId: this.game.id,
                                    },
                                })
                                .catch((err) => {})
                            this.teams.table.data.splice(index, 1)
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
    },
}
</script>
